import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const MenuDE = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="de" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Speisekarte</h1>
          <div>
            <Link to="/menu">HR</Link>
            <Link to="/menu-en">EN</Link>
            <Link to="/menu-it">IT</Link>
            <Link to="/menu-fr">FR</Link>
            <Link to="/menu-rus">RUS</Link>
          </div>
        </div>
        <h3>Kalte Vorspeisen</h3>
        <ul>
          <li>Trüffelcreme mit Trüffelkäse</li>
          <li>Salat mit Garnelen, Ziegenkäse und frischen Gurken</li>
          <li>Rinderfilet-Tatar mit Aioli und Zwieback</li>
          <li>Vitello Tonnato</li>
          <li>Leberpastete mit Trüffeln und istrischer Wurst</li>
          <li>Hausgemachter Prosciutto mit Oliven</li>
          <li>
            Platte Fakin für 2 Personen (hausgemachter Prosciutto, hausgemachte
            Würste, hausgemachter Käse und Trüffelkäse)
          </li>
        </ul>
        <h3>Suppen</h3>
        <ul>
          <li>Schwarze Trüffelsuppe</li>
          <li>Spargelcreme</li>
          <li>Istrische Maismanestra</li>
        </ul>
        <h3>Warme Vorspeisen</h3>
        <ul>
          <li>Pasta Fuži mit schwarzen Trüffeln</li>
          <li>Omelette Fritaja mit schwarzen Trüffeln</li>
          <li>Spaghetti mit Garnelen, Miesmuscheln und Kirschtomaten</li>
          <li>Paccheri mit Bolognese-Sauce und frischem Meerrettich</li>
          <li>
            Römische Gnocchi in Trüffelsauce mit istrischem Pancetta-Chips
          </li>
          <li>Rindfleischeintopf mit hausgemachten Gnocchi</li>
        </ul>
        <h3>Hauptspeisen</h3>
        <ul>
          <li>
            Wolfsbarschfilet, gratiniert mit einer Kruste aus Kräutern,
            Ratatouille und Kartoffelpüree mit Schnittlauch
          </li>
          <li>
            Hähnchenfilet mit Käsefüllung, im Backofen überbacken, auf einem
            Bett aus Spinat und Champignons
          </li>
          <li>
            Lammkotelett in einer Thymiansauce, Bratkartoffeln mit Rosmarin und
            Julienne-Gemüse
          </li>
          <li>
            Beefsteak mit schwarzen Trüffeln auf einem Bett aus Kartoffelpüree
          </li>
          <li>
            Beefsteak in einer Teran (wein) - Sauce mit Selleriepüree und
            Salatgarnitur
          </li>
          <li>
            Hausgemachte Schweinelende mit schwarzem Trüffel und Ofenkartoffeln
          </li>
          <li>Gegrilltes Gemüse</li>
          <li>Wild-Ragout mit überbackener Polenta</li>
        </ul>
        <h3>Nachspeisen</h3>
        <ul>
          <li>Schokoladen-Soufflé</li>
          <li>Schokoladentrüffelcreme</li>
          <li>Schokoladenpralinen mit Trüffeln</li>
          <li>Pfannkuchen in einer Orangen-Cointreau-Sauce</li>
          <li>Crème-Brûlée</li>
        </ul>
        <h3>Salate</h3>
        <ul>
          <li>Trüffelsalat</li>
          <li>Tägliche Auswahl an Salaten der Saison</li>
          <li>Pladanj istarskih sireva s maslinovim uljem, medom i bademima</li>
        </ul>
      </div>
    </Layout>
  )
}

export default MenuDE
